@media (max-width: 767px) {
    .inputsPadding {
        padding: 0 15px;
    }

    .titlePadding {
        padding: 15px;
    }
}

@media (min-width: 768px) {
    .inputsPadding {
        padding: 0px 30px;
    }

    .titlePadding {
        padding: 30px 0 10px 0;
    }
}
